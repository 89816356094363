.input-custom-select__list-element button {
  color: #666;
  border-bottom: dotted 1px #808080;
  width: 100%;
  display: block;
  padding: 5px 8px;
  text-align: left;
}

.input-custom-select__list-element button:hover {
  color: white;
  background-color: #666;
}

.input-custom-select__list_hidden {
  display: none;
}

.input-custom-select__list_shown {
  top: 44px;

  display: block;
}

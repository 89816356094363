@keyframes jello {
  82% {
    transform: none;
  }
  87% {
    transform: skewX(7deg) skewY(5deg);
  }
  89% {
    transform: skewX(-6deg) skewY(-5deg);
  }
  91.6666% {
    transform: skewX(2.6deg) skewY(3.6deg);
  }
  94% {
    transform: skewX(-2.8deg) skewY(-1.8deg);
  }
  96% {
    transform: skewX(1.4deg) skewY(2.4deg);
  }
  97.6666% {
    transform: skewX(-1.5deg) skewY(-1deg);
  }
  99% {
    transform: skewX(0.4deg) skewY(0.6deg);
  }
  100% {
    transform: none;
  }
}

.jello {
  animation: jello 8s infinite;
  transform-origin: center;
}

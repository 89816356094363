.home-quick-search {
  background-image: url('https://cdn.sitephotos.sierrastatic.com/984_hero_20180517182125928078000000-o-20180815021214.jpg');
}
.communities .img {
  background-image: url('https://cdn.sitephotos.sierrastatic.com/984_community_communities-20180803092630.jpg');
}
.about .bg {
  background-image: url('https://cdn.sitephotos.sierrastatic.com/984_contentbackground_20180102210417699653000000-o-20180815020917.jpg');
}
.home-quick-search.si-bgp--top {
  background-position: center top;
}
.home-quick-search.si-bgp--center {
  background-position: center center;
}
.home-quick-search.si-bgp--bottom {
  background-position: center bottom;
}

.property_search_form__check_box {
  float: left;
}

.property_search_form__check_box input {
  display: none;
}

.property_search_form__check_box label {
  display: block;
  border: solid 1px #d9d9d9;
  margin-right: 52px;
  cursor: pointer;
  padding: 10px;
  font-weight: 400;
  position: relative;
  margin-bottom: 15px;
  white-space: nowrap;
  height: 44px;
}

.property_search_form__check_box .disabled {
  display: block;
  border: solid 1px #d9d9d9;
  margin-right: 52px;
  cursor: pointer;
  padding: 10px;
  font-weight: 400;
  position: relative;
  margin-bottom: 15px;
  white-space: nowrap;
  height: 44px;
  color: #aaa;
}

.property_search_form__check_box label:after {
  position: absolute;
  content: '';
  width: 36px;
  height: 44px;
  display: block;
  right: -36px;
  top: -1px;
  border: solid 1px #d9d9d9;
}

.property_search_form__check_box__checked label:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f00c';
  color: #1c3144;
  font-size: 17px;
  padding-left: 8px;
  padding-top: 12px;
}

.property_search_form__check_box__disabled label:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f00c';
  color: #aaa;
  font-size: 17px;
  padding-left: 8px;
  padding-top: 12px;
}
